.green,
.success,
.Active,
.Completed {
    color: #2cad3c !important;
}

.white {
    color: white !important;
}

.whiteish {
    color: #f7f7f7 !important;
}

.white-ish {
    color: #9a9a9a !important;
}
.bg_jeff_blue {
    background-color: #002549 !important;
}

.blue-dark,
.Shopping {
    color: #002549 !important;
}

.navy {
    color: #0433C4 !important;
}

.gray {
    color: #6c6c6c !important;
}

.lile {
    color: #8CA0DE !important
}

.bg-lile {
    background-color: #8CA0DE !important
}

.black {
    color: black !important;
}

.blue-link {
    color: #03aeef !important
}

.blue,
.Pending {
    color: #004095 !important;
}

.yellow,
.Waitlisted,
.Waiting {
    color: #FFC00E !important;
}

.orange {
    color: #F3A833 !important;
}

.red,
.rejected,
.Inactive,
.Denied,
.Late {
    color: #dc3545 !important;
}

.input-danger {
    border: 2px solid #dc3545 !important;
}

.teal {
    color: teal;
}

.mint {
    color: #E1F0E5 !important
}

.bg-mint {
    background-color: #E1F0E5 !important
}

.cream {
    color: #FFF5DF !important
}

.bg-cream {
    background-color: #FFF5DF !important
}

;

.lavander {
    color: #E7EBF7 !important
}

.bg-lavander {
    background-color: #E7EBF7 !important
}

;

.purple,
.Tryout {
    color: #77498B !important;
}

.bg-purple {
    background-color: #77498B !important;
}

.bg-green {
    background-color: #289F45 !important;
}

.bg-green-dark {
    background-color: #0D561E !important;
}

.bg-yellow {
    background-color: #FFC00E !important
}

.bg-dark-blue {
    background-color: #143465 !important;
}

.bg-navy {
    background-color: #0433C4 !important;
}

.bg-blue-light {
    background-color: #E8EBF6 !important;
}

.bg-darker-blue,
.bg-blue-dark {
    background-color: #001A36 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: black !important
}

.bg-blackish {
    background-color: #252525 !important
}

.bg-gray {
    background-color: #CCC !important
}

.bg-red {
    background-color: #f44336 !important;
}

.bg-gray-darkish {
    background-color: #8F959C !important
}

.bg-whiteish {
    background-color: #EAEAEA !important;
}

.bg-gray-dark {
    background-color: #525252 !important
}

.bg-Competitive {
    background-color: #6a0e33;
}

.bg-Camp,
.bg-orange {
    background-color: #fd930c;
}

.bg-Recreational {
    background-color: #259bd9;
}

.bg-Tournament {
    background-color: #4fb03c;
}

.color-Competitive {
    color: #6a0e33;
}

.color-Camp {
    color: #fd930c;
}

.color-Recreational {
    color: #259bd9;
}

.color-Tournament {
    color: #4fb03c;
}

.jeff_blue {
    color: #013469 !important;
}

.jeff_blue_back {
    background-color: #013469 !important;
}

.inner-shadow {
    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
}

input.dark,
button.dark,
textarea.dark {
    background-color: #AAA;
    padding: 8px;
    display: block;
    border: none;
    width: 100%;
}

.premium-gradient {
    background: rgb(100, 169, 212);
    background: linear-gradient(90deg, rgba(100, 169, 212, 1) 0%, rgba(172, 215, 100, 1) 100%);
}
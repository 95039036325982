.bigest-font {
    font-size: 3.5em !important;
}

.big-font {
    font-size: 2em !important;
}
.font-150 {
    font-size: 150px !important;
}
.font-100 {
    font-size: 100px !important;
}
.font-90 {
    font-size: 90px !important;
}
.font-80 {
    font-size: 80px !important;
}
.font-70 {
    font-size: 70px !important;
}
.font-60 {
    font-size: 60px !important;
}
.font-50 {
    font-size: 50px !important;
}
.font-40 {
    font-size: 40px !important;
}
.font-36 {
    font-size: 26px !important;
}
.font-30 {
    font-size: 30px;
}
.font-25 {
    font-size: 25pt !important;
}
.font-24 {
    font-size: 24px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-20 {
    font-size: 20pt !important;
}

.font-18 {
    font-size: 18pt !important;
}

.font-16 {
    font-size: 16pt !important;
}

.font-14 {
    font-size: 14pt !important;
}

.font-12 {
    font-size: 12pt !important;
}

.font-11 {
    font-size: 11pt !important;
}

.font-10 {
    font-size: 10pt !important;
}

.font-9 {
    font-size: 9pt !important;
}

.font-8 {
    font-size: 8pt !important;
}

.font-7 {
    font-size: 7pt !important;
}

.font-6 {
    font-size: 6pt !important;
}

.toUpper {
    text-transform: uppercase;
}

.toBold {
    
    font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.toNormal {
    font-weight: normal !important;
}

.dodge {
    mix-blend-mode: exclusion;
}

.burn {
    mix-blend-mode: color-burn;
}

.text-shadow {
    text-shadow: 1px 1px 4px #000000;
}

.watermark {
    font-size: 8pt;
    color: #ccc;
    text-align: center;
}

.vertical-text {
	transform: rotate(90deg);
    transform-origin: left top 0;
    float: left;
}

.underline, .link {
    text-decoration: underline !important;
}

.permanent-marker {
    font-family: 'Permanent Marker', cursive;
}

.roboto {    
    font-family: 'Roboto', sans-serif !important;    
}

.roboto-mono {    
    font-family: 'Roboto Mono', monospace;
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
}
.montserrat.toBold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.bungee {
    font-family: 'Bungee', cursive;
}

.kanit {
    font-family: 'Kanit', sans-serif;    
}

.hind {
    font-family: 'Hind', sans-serif;    
}

.anton {
    font-family: 'Anton', sans-serif;
}

.noto {
    font-family: 'Noto Sans JP', sans-serif;
}

.hero-text {  
    opacity: 0.88;  
    font-size: 90px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 3.2px;
    text-align: center;
    color: white;
  }

  .heroish-text {  
    opacity: 0.88;  
    font-size: 50px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 3.2px;
    text-align: center;
    color: white;
  }

  .super-hero-text {  
    opacity: 0.88;  
    font-size: 260px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 3.2px;
    text-align: center;
    color: white;
  }
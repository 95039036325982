#payment-plans {
    /*margin-top: 9em;*/
}

#payment-plans .tile, .payment-plan-tile {
    width: 300px;
    min-height: 80px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    margin-right: 1em;
    margin-bottom: 1em;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

#payment-plans .tile:hover {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

@media (max-width: 870px) {
    #payment-plans .tile, .payment-plan-tile {
        width: 100%;
        margin-left: 1em;
    }
    #payment-plans .tile:hover {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}